<template>
  <div class="photoTrapDialog">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <span
        v-if="lastRefresh"
        v-tooltip="`${ lastRefresh | dateTime_fromNow }`"
        data-toggle="tooltip"
        data-placement="top"
        class="badge badge-secondary mb-4 float-right"
      >Last refreshed: {{ lastRefresh | dateTime_dateTime }}</span>
      <div class="clearfix" />

      <PhotoTrapCameraList
        ref="photoTrapCameraList"
        :cameras="status"
      />

      <Sidebar
        v-if="currentId"
        :show-sidebar="showSidebar"
        :sidebar-width="800"
        :cameras="status"
        @close="hide"
      >
        <template v-if="currentComponent === 'PhotoTrapFilterForm'">
          <PhotoTrapFilterForm @findItems="findItems" />
          
          <LoadingPlaceholder
            v-if="loadingImages"
            class="mt-4 mb-4"
          />
          <template v-else>
            <PhotoTrapResult
              :load-debug-images="loadDebugImages"
              :items="items"
              class="mb-4"
            />
          </template>
        </template>

        <template v-else>
          <table class="defaultTable">
            <tbody>
              <tr>
                <th>Operation State</th>
                <td>{{ selectedCamera.state }}</td>
              </tr>
              <tr>
                <th>Last Camera Status</th>
                <td>{{ selectedCamera.lastCameraStatus }}</td>
              </tr>
              <tr>
                <th>Error</th>
                <td>{{ selectedCamera.cameraError }}</td>
              </tr>
              <tr>
                <th>Camera Address</th>
                <td>{{ selectedCamera.address }}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </Sidebar>
    </template>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "PhotoTrapDialog",
  components: {
    PhotoTrapCameraList: () => import('@/components/PhotoTrap/PhotoTrapCameraList.vue'),
    PhotoTrapFilterForm: () => import('@/components/PhotoTrap/PhotoTrapFilterForm.vue'),
    PhotoTrapResult: () => import('@/components/PhotoTrap/PhotoTrapResult.vue'),
    Sidebar: () => import('@/components/Base/Sidebar.vue')
  },
  mixins: [
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      status: null,
      loading: true,
      loadingImages: false,
      showSidebar: false,
      items: null,
      lastRefresh: null,
      skip: 0,
      take: 100,
      temporaryFilter: null,
      currentId: null,
      loadDebugImages: true,
      capturedObjects: false
    }
  },
  computed: {
    selectedCamera: function () {
      if (this.status == null) {
        return;
      }
      return this.status.find((camera) => {
        return camera.cameraId === this.currentId;
      });
    }
  },
  created () {
    this.getPhotoTrapStatus();
    this.$eventBus.$on('photoTrap_find', this.showFindItemsSidebar);
    this.$eventBus.$on('photoTrap_detail', this.showDetailSidebar);
    this.$eventBus.$on('photoTrap_disable', this.photoTrapDisable);
    this.$eventBus.$on('photoTrap_enable', this.photoTrapEnable);
  },
  beforeDestroy () {
    this.$eventBus.$off('photoTrap_find', this.showFindItemsSidebar);
    this.$eventBus.$off('photoTrap_detail', this.showDetailSidebar);
    this.$eventBus.$off('photoTrap_disable', this.photoTrapDisable);
    this.$eventBus.$off('photoTrap_enable', this.photoTrapEnable);
  },
  methods: {
    photoTrapDisable () {
      this.$snotify.warning("Function not implemented yet.");
    },
    photoTrapEnable () {
      this.$snotify.warning("Function not implemented yet.");
    },
    showFindItemsSidebar (cameraId) {
      this.show(cameraId, "PhotoTrapFilterForm");
    },
    showDetailSidebar (cameraId) {
      this.show(cameraId, "PhotoTrapDetails");
    },
    show (currentId, currentComponent) {
      if (currentId) {
      this.currentId = currentId;
      }
      if (currentComponent) {
        this.currentComponent = currentComponent;
      }
      this.showSidebar = true;
      let dateNow = this.dateTime_iso(new Date());
      let dateTimeNow = new Date();
      let filter = {
        dates: [dateNow, dateTimeNow]
      }
      this.findItems(filter);
    },
    hide () {
      this.currentId = null;
      this.currentComponent = null;
      this.showSidebar = false;
    },
    findItems (filter) {
      this.temporaryFilter = {
        filterStartDate: filter.dates[0], //`${ yyyy }/${ mm }/${ dd }`,
        filterEndDate: filter.dates[1], //`${ yyyy }/${ mm }/${ ddTomorrow }`,
        skip: this.skip,
        take: this.take,
        cameraId: this.currentId,
        minAnnotationScore: this.capturedObjects ? 0.005 : -1
      };

      this.loadingImages = true;
      this.items = null;
      this.axios.post(`/PhotoTrap/GetItems/${ this.installationId }`, this.temporaryFilter)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.items = response.data;
        })
        .finally(() => {
          this.loadingImages = false;
          this.lastRefresh = Date.now();
          this.$nextTick(() => {
            window.$('[data-toggle="tooltip"]').tooltip();
          });
        });
    },
    getPhotoTrapStatus () {
      this.axios.get(`/PhotoTrap/GetStatus/${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.cameraStatus == null) {
            return;
          }
          this.status = response.data.cameraStatus;
        }).finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>

<style scoped>
.cameraIcon{
  font-size: 3rem;
}
</style>
